const tableData = [
  {
    id: 1,
    name: 'Recruiting Mangroupsr',
    course: 'Web Desing',
    evaluation: 10,
  }, {
    id: 2,
    name: 'Vocês estão avisados',
    course: 'Marketing Digital',
    evaluation: 5,
  }, {
    id: 3,
    name: 'Recruiting Mangroupsr',
    course: 'OOP Programming',
    evaluation: 9,
  }, 
]

export { tableData };

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { tableData } from "./data-advancedtable";

/**
 * Quality component
 */
export default {
  page: {
    title: "Relatórios de Qualidade",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tableData: tableData,
      title: "Relatórios de Qualidade",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Relatórios de Qualidade",
          active: true,
        },
      ],
      showModal: false,
      showModalGroups: false,
      selectedGroups: [],
      selectedStudentName: "",
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        name: "Nome",
        course: "Curso",
        evaluation: "Avaliação",
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        {
          key: "id",
          sortable: true,
          label: this.fieldAliases.id,
          thStyle: { width: "8%" },
        },
        { key: "name", sortable: true, label: this.fieldAliases.name },
        { key: "course", sortable: true, label: this.fieldAliases.course },
        {
          key: "evaluation",
          sortable: true,
          label: this.fieldAliases.evaluation,
        },
      ];
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },

    deleteItem() {},
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
      :enableCad="false"
      @newCad="handleNewCad"
    />
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3 header-title">
              <span>Cursos em Geral</span>
              <hr />
            </h5>
            <div class="row">
              <div class="col-12 d-flex flex-column align-items-center">
                <h3>10,00</h3>
                <span>Nota média</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3 header-title">
              <span>Cursos Com as Melhores Notas</span>
              <hr />
            </h5>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <span title="Teste do Sistema"> 
                      1. Teste do Sistema 
                    </span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span> 10,00 </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-8">
                    <span title="Teste de Regras AVA">
                      2. Teste de Regras AVA
                    </span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span> 10,00 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3 header-title">
              <span>Cursos Com as Ppiores Notas</span>
              <hr />
            </h5>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <span title="Teste do Sistema"> 
                      1. Teste do Sistema 
                    </span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span> 10,00 </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-8">
                    <span title="Teste de Regras AVA">
                      2. Teste de Regras AVA
                    </span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span> 10,00 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Relatórios de Qualidade</h4>
            <form class="row mb-4" method="get">
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="course_id">Cursos</label>
                  <select
                    class="form-control select2 select2-hidden-accessible"
                  ></select>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="form-group">
                  <label for="rating">Avaliação</label>
                  <input
                    type="number"
                    id="rating"
                    name="rating"
                    class="form-control"
                    placeholder="0"
                    value=""
                  />
                </div>
              </div>

              <div class="col-xl-2">
                <label for=".." class="text-white">..</label>
                <button type="submit" class="btn btn-primary btn-block w-100">
                  Filtro
                </button>
              </div>
            </form>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select
                      class="form-control form-control-sm form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                    Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(name)="obj">
                  <div class="actions-cell">
                    <b>{{ obj.item.name }}</b>
                  </div>
                </template>
                <template #cell(contract)="obj">
                  <div class="actions-cell">
                    <span
                      class="badge ms-1 w-100"
                      :class="
                        obj.item.contract == 'A' ? 'bg-success' : 'bg-warning'
                      "
                    >
                      <h6 class="m-0">
                        {{ obj.item.contract == "A" ? "Ativo" : "Inativo" }}
                      </h6>
                    </span>
                  </div>
                </template>
                <template #cell(status)="obj">
                  <div class="actions-cell">
                    <span
                      class="badge ms-1"
                      :class="
                        obj.item.status == 'A' ? 'bg-success' : 'bg-warning'
                      "
                    >
                      <h6 class="m-0">
                        {{ obj.item.status == "A" ? "Ativo" : "Inativo" }}
                      </h6>
                    </span>
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="obj">
                  <div class="actions-cell">
                    <b-button
                      class="w-100"
                      pill
                      @click="deleteItem(obj.item)"
                      variant="outline-primary"
                    >
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
